import React from "react"
import Header from "../../components/header"
import HeaderLeistung from "../../components/headerLeistung"
import LeistungBox from "../../components/boxLeistung"
import Footer from "../../components/footer"
import ContactBox from "../../components/boxContact"

import imgKupferZink01 from "../../assets/leistungen/kupfer01.jpg"
import imgKupferZink02 from "../../assets/leistungen/zink01.jpg"

export default () => (
  <div>
    <Header />
    <HeaderLeistung Title="Kupfer- &amp; Zinkarbeiten" Content="" />
    <LeistungBox
      Color="gray"
      Image={imgKupferZink01}
      IsImageLeft={true}
      Title="Kupferarbeiten und Kupferdächer"
      Content="Kupfer hat die stärkste Bruchdehnung von allen Baumetallen, d.h. es lässt sich am stärksten dehnen und verformen, bevor das Material bricht.
        Es eignet sich daher sehr gut bei komplizierten Anschlüssen, bei denen starke Verformungen erforderlich sind.&nbsp; 
        Im Montagezustand ist Kupfer rot-metalisch glänzend.&nbsp;
        Durch Witterungseinflüsse bildet sich rasch eine Patinadeckschicht, so dass sich das geneigte Dach in einem hellgrünen bis blaugrünen Farbton zeigt.&nbsp; 
        Bei senkrechten Flächen ist nicht mit einer Grünfärbung sondern mit einer tiefbraunen bis anthrazitgrauen Färbung zu rechnen.&nbsp;
        <p />
        Kupfer lässt sich hervorragend kanten, falzen und biegen.&nbsp; 
        Unter anderem lässt sich das Metall kunstvoll bearbeiten."
    />
    <LeistungBox
      Color="white"
      Image={imgKupferZink02}
      IsImageLeft={false}
      Title="Titanzink"
      Content="Der verwendete Werkstoff bei einem Zinkdach ist Titanzink. Diese Legierung enthält mind. 99,995% Feinzink.&nbsp; 
        Dadurch bekommt das Material eine verbesserte Dauerstandfestigkeit, die Wärmedehnung ist sehr gering und es lässt sich gut verarbeiten.&nbsp; 
        Durch Witterungseinflüsse bildet sich eine graublaue Schutzschicht.&nbsp; 
        Die Verarbeitung von Titanzink ist problemlos.&nbsp; 
        <p />
        Es lässt sich z.B. durch Kanten oder Falzen an alle Bauformen anpassen."
    />
    <ContactBox isFoot="true" />
    <Footer />
  </div>
)
